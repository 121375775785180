<template>
  <Layout :loading="isLoading">
    <div v-if="company">
      <template v-if="company">
        <div
          v-if="hasMoreThanOneCompany"
          class="lowco-textbox lowco-textbox-warn mb-1"
        >{{ selectCompanyText }}</div>
        <div
          v-if="successfullySaved"
          class="lowco-textbox lowco-textbox-success"
        >Sauvegarde effectuée avec succès</div>
        <Accordion title="Profil" defaultDisplayContent>
          <ProfileDetails
            :company="company"
            :unvalidFields="unvalidFields"
            @company-change="setCompany"
            @status-change="updateStatus"
          />
        </Accordion>
        <Accordion title="Horaires" :hasError="!isFieldValid('openings')">
          <Openings :company="company" @company-change="setCompany" />
        </Accordion>
        <Accordion
          title="Offre et démarche"
          :hasError="!isFieldValid('descriptionPhilo') || !isFieldValid('descriptionOffer')"
        >
          <div class="mt-1">
            <label for="descriptionOffer">Description de votre offre</label>
            <textarea
              required
              type="text"
              id="descriptionOffer"
              v-model="company.descriptionOffer"
              @change="handleDescriptionChange"
              name="descriptionOffer"
              maxlength="300"
              :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('descriptionOffer') }]"
            />
          </div>
          <div class="mt-1">
            <label for="descriptionPhilo">Description de votre démarche</label>
            <textarea
              required
              type="text"
              id="descriptionPhilo"
              v-model="company.descriptionPhilo"
              @change="handleDescriptionChange"
              name="descriptionPhilo"
              rows="8"
              maxlength="600"
              :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('descriptionPhilo') }]"
            ></textarea>
          </div>
          <div class="mt-1">
            <label>Badges</label>
          </div>
          <p class="small">
            Ne sélectionner que les badges qui vous représentent le mieux
            pour ne pas créer de fausses attentes !
            Ils servent de filtre lors de la recherche.
          </p>
          <div class="link-wrapper">
            <a class="link" :href="badgesLink" target="_blank">Découvrir les badges</a>
          </div>
          <div class="badge-list">
            <div
              class="select-badge"
              v-for="badge in badges"
              :key="badge.id"
              :class="{ 'select-badge-unselected': !badge.isChecked }"
            >
              <img
                class="badge"
                :src="activeBadgeUrl(badge.image)"
                :alt="badge.name"
                @click="toggleBadge(badge)"
              />
            </div>
          </div>
        </Accordion>
        <Accordion title="Images du profil" :hasError="!isFieldValid('medias')">
          <Images :images="images" @on-image-added="addImage" @on-image-delete="deleteMedia" />
        </Accordion>
        <Accordion title="Vidéos">
          <Videos :videos="videos" @on-video-deleted="deleteMedia" @on-video-added="addVideo" />
        </Accordion>
        <div class="lowco-textbox lowco-textbox-warn my-1">
          RENDEZ-VOUS SUR LA PAGE
          <router-link to="/catalog">CATALOGUE</router-link>POUR ENREGISTRER VOS ARTICLES !
        </div>
        <div class="save">
          <Button
            class="save__button"
            text="Sauvegarder"
            :disabled="!canSubmit"
            @buttonClick="save"
            extraSmall
          />
        </div>
        <Modal
          :isFreezed="isLoading"
          :isDisplayed="isWarningModalOpen"
          @close-modal="isWarningModalOpen = false"
        >
          <h2 class="mb-2">Attention</h2>
          <p>Vous avez des changements non sauvegardés !</p>
          <p>Voulez-vous continuer ?</p>
          <div class="buttons">
            <Button extraSmall text="Continuer sans sauvegarder" isDanger @buttonClick="leave" />
            <Button extraSmall text="Sauvegarder et continuer" @buttonClick="saveAndLeave" />
          </div>
        </Modal>
      </template>
    </div>
    <div v-else>No Enterprise</div>
  </Layout>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';

import Openings from '@/components/profile/Openings.vue';
import Images from '@/components/profile/Images.vue';
import ProfileDetails from '@/components/profile/Profile.vue';
import Layout from '@/components/common/Layout.vue';
import Videos from '@/components/profile/Videos.vue';
import { Button, Accordion, Modal } from '@/components/common';

import initialBadges from '@/constants/badges.constants';
import mediaTypes from '@/constants/mediaTypes.constants';

import lowcoApi from '@/api/lowco-api';

import useCurrentCompany from '@/composables/useCurrentCompany';
import useWindowSize from '@/composables/useWindowSize';

import companyUtils from '@/utils/company.utils';

const PWA_LINK = process.env.VUE_APP_POST_LOGOUT_REDIRECT;

export default defineComponent({
  name: 'Profile',
  components: {
    Accordion,
    Button,
    Openings,
    Images,
    ProfileDetails,
    Layout,
    Videos,
    Modal,
  },
  setup() {
    const { isIPadOrGreater } = useWindowSize();

    const company = ref(null);
    const successfullySaved = ref(false);
    const isLoading = ref(false);
    const badges = ref(initialBadges);
    const hasInvoiceAddress = ref(false);

    const hasUnsavedChanges = ref(false);
    const intendedRoute = ref(null);
    const isWarningModalOpen = ref(false);

    const router = useRouter();
    const {
      currentCompanyId,
      companies,
      fetchCompanies,
    } = useCurrentCompany();

    // Computed properties
    const hasMoreThanOneCompany = computed(() => companies.value.length > 1);
    const companySelectedBadges = computed(() => badges.value
      .filter((b) => b.isChecked)
      .map((b) => b.id));
    const badgesLink = computed(() => `${PWA_LINK}/badges`);
    const images = computed(() => company.value.medias.filter((m) => m.mediaType === mediaTypes.IMAGE));
    const videos = computed(() => company.value.medias.filter((m) => m.mediaType === mediaTypes.VIDEO));
    const selectCompanyText = computed(() => (
      isIPadOrGreater.value
        ? 'Vous pouvez changer de point de vente via la liste déroulante située en haut de page.'
        : "Accédez à vos différents points de vente via la liste déroulante sur la page 'Plus'."
    ));
    const canSubmit = computed(() => companyUtils.validate(
      company.value,
      hasInvoiceAddress.value,
    ).valid);
    const unvalidFields = computed(() => companyUtils.validate(
      company.value,
      hasInvoiceAddress.value,
    ).unvalidFields);

    // Methods
    const setCompany = (value, hasInvoiceAdd) => {
      if (value) {
        company.value = { ...value };
        hasUnsavedChanges.value = true;
      }

      if (typeof hasInvoiceAdd === 'boolean') {
        hasInvoiceAddress.value = hasInvoiceAdd;
      }
    };

    const isFieldValid = (fieldName) => !unvalidFields.value.includes(fieldName);

    const handleDescriptionChange = (event) => {
      const { name, value } = event.target;
      hasUnsavedChanges.value = true;

      company.value = {
        ...company.value,
        [name]: value,
      };
    };

    const addImage = async (image) => {
      const { blob, name } = image;

      const formData = new FormData();

      formData.append('Files', blob, name);

      try {
        const { medias } = await lowcoApi.addImageToCompany(currentCompanyId.value, formData);

        company.value = {
          ...company.value,
          medias,
        };
      } catch (error) {
        // Handle error
      }
    };

    const addVideo = async (video) => {
      try {
        const { medias } = await lowcoApi.addVideoToCompany(currentCompanyId.value, video);

        company.value = {
          ...company.value,
          medias,
        };
      } catch (err) {
        // Handle error
      }
    };

    const initBadges = () => {
      badges.value = badges.value.map((b) => {
        if (company.value.badges.includes(b.id)) {
          return {
            ...b,
            isChecked: true,
          };
        }

        return {
          ...b,
          isChecked: false,
        };
      });
    };

    const loadCompany = async () => {
      try {
        isLoading.value = true;
        const result = await lowcoApi.getMyEnterprise(currentCompanyId.value);

        company.value = result;

        if (!result.billingAddress) {
          result.billingAddress = {
            street: '',
            zipCity: '',
            city: '',
          };
        }

        hasInvoiceAddress.value = !!result.billingAddress?.street;

        initBadges();
      } catch (err) {
        // Handle error
      } finally {
        isLoading.value = false;
      }
    };

    const save = async () => {
      company.value.badges = companySelectedBadges.value;
      company.value.vat = company.value.vat.replace(/[\s.]/g, '');

      try {
        isLoading.value = true;
        await lowcoApi.updateCompany(company.value);

        successfullySaved.value = true;
        fetchCompanies();
        loadCompany();
      } catch (err) {
        // Handle error
      } finally {
        isLoading.value = false;
      }
    };

    const activeBadgeUrl = (activeBadge) => require(`../assets/images/badges/${activeBadge}.svg`);

    const toggleBadge = (badge) => {
      // eslint-disable-next-line no-param-reassign
      badge.isChecked = !badge.isChecked;
      hasUnsavedChanges.value = true;
    };

    const updateStatus = async (newStatus) => {
      try {
        await lowcoApi.updateCompanyStatus(currentCompanyId.value, newStatus);
      } catch (error) {
        // Handle error
      }
    };

    const deleteMedia = async (mediaId) => {
      try {
        await lowcoApi.removeCompanyMedia(currentCompanyId.value, mediaId);

        company.value = {
          ...company.value,
          medias: company.value.medias.filter((m) => m.id !== mediaId),
        };
      } catch (error) {
        // Handle error
      }
    };

    const leave = async () => {
      initBadges();
      hasUnsavedChanges.value = false;
      router.push(intendedRoute.value);
    };

    const saveAndLeave = async () => {
      await save();
      leave();
    };

    // Watchers
    watch(currentCompanyId, (value) => {
      if (!value) {
        return;
      }

      loadCompany();
    });

    watch(successfullySaved, (value) => {
      if (!value) {
        return;
      }

      setTimeout(() => {
        successfullySaved.value = false;
      }, 3000);
    });

    // Lifecycle hooks
    onMounted(() => {
      if (!currentCompanyId.value) {
        router.push('/home');
        return;
      }

      loadCompany();
    });

    onBeforeRouteLeave((to, from, next) => {
      if (hasUnsavedChanges.value) {
        intendedRoute.value = to;
        isWarningModalOpen.value = true;

        return;
      }

      next();
    });

    return {
      company,
      isLoading,
      successfullySaved,
      badges,
      badgesLink,
      images,
      videos,
      hasMoreThanOneCompany,
      setCompany,
      handleDescriptionChange,
      addImage,
      addVideo,
      save,
      activeBadgeUrl,
      toggleBadge,
      updateStatus,
      deleteMedia,
      selectCompanyText,
      canSubmit,
      unvalidFields,
      isFieldValid,
      isWarningModalOpen,
      leave,
      saveAndLeave,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/variables.scss";
@import "@/assets/styles/common/mixins.scss";

.bold {
  font-weight: 600;
}

.save {
  position: fixed;
  bottom: calc(7rem + env(safe-area-inset-bottom));
  left: calc(2rem + env(safe-area-inset-left));
  right: calc(2rem + env(safe-area-inset-right));

  text-align: right;
  margin-top: 2rem;
  z-index: 9999;

  &__button {
    width: 100%;
  }

  @include ipad {
    position: static;
    z-index: 0;

    &__button {
      min-width: 30rem;
      width: auto;
    }
  }
}

.badge-list {
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, 9rem);
  justify-content: center;
  gap: 1rem;

  @include ipad {
    justify-content: start;
  }
}

.select-badge {
  width: 9rem;
  display: inline-block;
  cursor: pointer;
  text-align: center;

  > img {
    max-width: 15rem;
  }

  &-unselected {
    img {
      filter: grayscale(100%);
    }
  }
}

.small {
  font-size: 1.3rem;
  margin: 5px 0 10px 0;
}

.link-wrapper {
  display: flex;
  justify-content: center;
  align-self: start;
}

.link {
  font-size: 1.8rem;
  color: $dark-green;
  margin: 2rem auto 0 auto;
}

.lowco-textbox {
  a {
    font-size: inherit;
    text-decoration: underline;
  }
}

.buttons {
  margin-top: 2rem;
  @include spacing-children("vertical", 1rem);

  @include sm {
    display: flex;
    justify-content: flex-end;
    @include spacing-children("horizontal", 1rem);
    @include spacing-children("vertical", 0);
  }
}
</style>
