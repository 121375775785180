<template>
  <div>
    <p class="small mb-1">
      Ajoutez une plage horaire sur vos jours d'ouverture via le bouton "+".
      Vous pouvez ajouter plusieurs plages horaires sur une journée, si nécessaire.
    </p>
    <div class="days" v-for="day in days" :key="day.id">
      <div class="mb-1 dayName">{{ day.name }}</div>
      <div class="hours" v-for="opening in getOpeningDays(day.id)" :key="opening.id">
        <input
          type="time"
          class="lowco-textbox"
          name="startTime"
          :value="opening.startTime"
          @blur="opening.startTime = formatTime($event)"
        />
        <input
          type="time"
          class="lowco-textbox"
          name="endTime"
          :value="opening.endTime"
          @blur="opening.endTime = formatTime($event)"
        />
        <button class="lowco-button button-remove" @click="removeOpening(day.id, opening)">
          <FontAwesomeIcon :icon="['fas', 'minus']" />
        </button>
      </div>
      <div class="col-1">
        <button class="lowco-button button-add" @click="addOpening(day.id)">
          <FontAwesomeIcon :icon="['fas', 'plus']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  toRefs,
  watch,
} from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';

import initialDays from '@/constants/days.constants';

library.add(faPlus, faMinus);

export default defineComponent({
  name: 'Openings',
  components: {
    FontAwesomeIcon,
  },
  props: {
    company: Object,
  },
  setup(props, { emit }) {
    const { company } = toRefs(props);

    const enterprise = ref({ ...company.value });

    watch(company, (value) => {
      enterprise.value = value;
    });

    const getOpeningDays = (dayId) => {
      if (enterprise.value.openings) {
        return enterprise.value.openings[dayId];
      }

      return [];
    };

    const onChangeOpening = () => emit('company-change', enterprise.value);

    const formatTime = (e) => {
      let time = e.target.value;

      const timeChunks = time.split(':');

      if (timeChunks.length < 3) {
        time += ':00';
      }

      onChangeOpening();

      return time;
    };

    const addOpening = (dayId) => {
      const currentOpenings = enterprise.value.openings && enterprise.value.openings[dayId]
        ? enterprise.value.openings[dayId]
        : [];

      enterprise.value = {
        ...enterprise.value,
        openings: {
          ...enterprise.value.openings,
          [dayId]: [
            ...currentOpenings,
            {
              startTime: '08:00:00',
              endTime: '17:00:00',
            },
          ],
        },
      };

      onChangeOpening();
    };

    const removeOpening = (dayId, opening) => {
      const day = enterprise.value.openings[dayId]
        ? enterprise.value.openings[dayId]
        : [];

      enterprise.value = {
        ...enterprise.value,
        openings: {
          ...enterprise.value.openings,
          [dayId]: [
            ...day.filter((item) => item.startTime !== opening.startTime
              && item.endTime !== opening.endTime),
          ],
        },
      };

      onChangeOpening();
    };

    return {
      enterprise,
      days: initialDays,
      getOpeningDays,
      addOpening,
      removeOpening,
      formatTime,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.button-remove,
.button-add {
  padding: 0.4rem 1rem;
  font-size: 1.8rem;
}

.button-remove {
  background-color: $error;
}

.button-add {
  width: 100%;
  margin-top: 1rem;
}

.days {
  & + .days {
    margin-top: 3rem;
  }
}

.dayName {
  font-size: 1.8rem;
}

.hours {
  max-width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    flex-grow: 1;
    font-size: 1.3rem;
    padding: 5px 10px;
  }

  & > * + * {
    margin-left: 1rem;
  }

  & + .hours {
    margin-top: 1rem;
  }
}

.small {
  font-size: 1.3rem;
}
</style>
